.LoginContainer {
    width: 350px;
    padding: 40px 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    text-align: center;
    margin: 0 auto;
    margin-top: 10rem;
  }
  
  .LoginTitle {
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
    font-weight: 600;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  form input {
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
  }
  
  form input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  form button {
    padding: 12px;
    margin-top: 10px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  form button:hover {
    background-color: #0056b3;
  }
  
  form button:active {
    transform: scale(0.98);
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .LoginContainer {
      width: 80%; /* Adjust width for tablets and smaller screens */
      padding: 30px 15px;
      margin-top: 10rem;
    }
  
    .LoginTitle {
      font-size: 22px;
    }
  
    form input,
    form button {
      font-size: 15px; /* Slightly reduce font size */
    }
  }
  
  @media (max-width: 480px) {
    .LoginContainer {
      width: 80%; /* Use almost full width for mobile screens */
      padding: 20px 10px;
      margin-top: 10rem;
    }
  
    .LoginTitle {
      font-size: 20px;
    }
  
    form input,
    form button {
      font-size: 14px;
      padding: 10px;
    }
  }