/* General container styling */
.upload-form {
    max-width: 900px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
.upload-form h3 {
    margin-top: 30px;
    color: #333;
    font-size: 24px;
  }
  
/* Drag and drop area styling */
.drag-drop-area {
    border: 2px dashed #aaa;
    padding: 40px;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 20px;
    transition: border-color 0.3s, background-color 0.3s;
    cursor: pointer;
    background-color: #fafafa;
  }
  
.drag-drop-area.drag-active {
    border-color: #4CAF50;
    background-color: #e8f5e9;
  }
  
.drag-drop-area p {
    font-size: 16px;
    color: #666;
    margin: 0;
  }
  
.drag-drop-area label {
    display: inline-block;
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #1976d2;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
/* Upload button styling */
.upload-form button {
    padding: 12px 20px;
    background-color: #1976d2;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 3rem;
    font-size: 16px;
  }
  
.upload-form button:hover {
    background-color: #1565c0;
  }
  
/* JSON Editor */
.json-editor {
    margin-top: 30px;
  }
  
.json-editor textarea {
    width: 100%;
    min-height: 400px;
    font-family: monospace;
    font-size: 14px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    box-sizing: border-box;
  }
  
.json-editor button {
    margin-top: 15px;
    padding: 12px 20px;
    background-color: #388e3c;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
.json-editor button:hover {
    background-color: #2e7d32;
  }
  
.uploaded-id {
    margin-top: 15px;
    font-size: 16px;
    color: #333;
  }
  
/* Name input styling */
.name-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 2rem;
    box-sizing: border-box;
  }

/* Responsive styling for mobile screens */
@media (max-width: 768px) {
  .upload-form,
  .json-editor {
      padding: 15px;
    }

  .upload-form h3 {
    font-size: 20px;
  }

  .drag-drop-area {
    padding: 30px;
    font-size: 14px;
  }
  
  .drag-drop-area p,
  .drag-drop-area label {
    font-size: 14px;
  }

  .upload-form button,
  .json-editor button {
    width: 100%;
    padding: 14px;
    font-size: 15px;
  }

  .json-editor textarea {
    font-size: 13px;
    padding: 10px;
  }
  
  .name-input {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .upload-form h3 {
    font-size: 18px;
  }
  
  .drag-drop-area {
    padding: 20px;
    font-size: 13px;
  }

  .upload-form button,
  .json-editor button {
    padding: 12px;
    font-size: 14px;
  }
  
  .json-editor textarea {
    font-size: 12px;
    padding: 8px;
  }

  .name-input {
    font-size: 13px;
    padding: 8px;
  }
}
