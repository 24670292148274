/* General styling */
.json-list {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .json-list h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Table styling for larger screens */
  table {
    width: 90%;
    border-collapse: collapse;
    margin-top: 10px;
    margin: 0 auto;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  td a {
    color: #007bff;
    text-decoration: none;
  }
  
  td a:hover {
    text-decoration: underline;
  }
  
  /* Action buttons */
  button {
    padding: 6px 12px;
    margin-right: 5px;
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #45a049;
  }


  .home-button {
    margin-bottom: 15px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .home-button:hover {
    background-color: #0056b3;
  }
  
  
  @media (max-width: 768px) {
    /* Stack table into cards for mobile */
    table, thead, tbody, th, td, tr {
      display: block;
    }
  
    thead tr {
      display: none;
    }
  
    tbody tr {
      margin-bottom: 15px;
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 10px;
    }
  
    td {
      text-align: right;
      padding: 10px 15px;
      position: relative;
    }
  
    /* Label each field for clarity on smaller screens */
    td::before {
      content: attr(data-label);
      position: absolute;
      left: 15px;
      font-weight: bold;
      color: #333;
      text-transform: capitalize;
    }
  
    /* Button styles */
    td:last-child {
      text-align: center;
      padding-top: 10px;
    }
  
    /* Full-width buttons for mobile */
    button {
      display: inline-block;
      margin: 5px auto;
      width: calc(50% - 10px);
      font-size: 14px;
      margin-right: 10px;
      margin-top: 2rem;
    }
  }
  