/* Container for the JSON editor */
.json-editor {
  padding: 20px;
  max-width: 80%;
  margin: 40px auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .json-editor {
    padding: 15px;
    max-width: 95%;
    margin: 20px auto;
  }
}

/* Editor title */
.json-editor h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .json-editor h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }
}

/* Name input */
.name-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

@media (max-width: 768px) {
  .name-input {
    padding: 8px;
    font-size: 14px;
  }
}

/* Toggle button between views */
.toggle-button {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-button:hover {
  background-color: #0056b3;
}

/* View container for JSON view or form view */
.view-container {
  margin-bottom: 20px;
}

/* Textarea for JSON view */
textarea {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
  height: 1000px;
}

@media (max-width: 768px) {
  textarea {
    font-size: 13px;
    padding: 8px;
  }
}

/* Styling the form fields */
.form-fields {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.field-group {
  border-left: 2px solid #e0e0e0;
  padding-left: 15px;
  margin-bottom: 15px;
}

/* Field name input */
.input-field-name {
  margin-bottom: 5px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .input-field-name {
    padding: 6px;
    font-size: 13px;
  }
}

/* Input fields */
.input-field {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .input-field {
    padding: 6px;
    font-size: 13px;
  }
}

/* Add, delete, and move buttons */
.add-button,
.delete-button,
.move-button {
  padding: 6px 10px;
  margin-top: 8px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .add-button,
  .delete-button,
  .move-button {
    padding: 5px 8px;
    font-size: 11px;
  }
}

.add-button {
  background-color: #5cb85c;
}

.delete-button {
  background-color: #d9534f;
}

.move-button {
  background-color: #0275d8;
}

.add-button:hover {
  background-color: #4cae4c;
}

.delete-button:hover {
  background-color: #c9302c;
}

.move-button:hover {
  background-color: #025aa5;
}

/* Array group styling */
.array-group {
  padding-left: 15px;
  margin-bottom: 15px;
  border-left: 2px solid #e0e0e0;
}

.array-item {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .array-item {
    padding: 8px;
  }
}

/* Array item label */
.array-label {
  font-weight: bold;
  color: #007bff;
  display: block;
  margin-bottom: 5px;
}

/* Save button */
.save-button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  color: #fff;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background-color: #218838;
}

@media (max-width: 768px) {
  .save-button {
    padding: 10px;
    font-size: 15px;
  }
}


.delete-icon {
  cursor: pointer;
  color: #ff4d4f; /* Use a red color to indicate deletion */
  margin-left: 10px;
  padding: 10px;
}

.delete-icon:hover {
  color: #ff7875; /* Lighter red on hover */
}
